import PillButton from '@client/components/generic/PillButton';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/AuthModalForCobrandSSO.css';
import { useSSORedirect } from '@client/hooks/sso-redirect.hooks';
import { getUserSpecialUserType } from '@client/store/selectors/auth.selectors';
import {
  getCobrandDisplayName,
  getCobrandId,
  getSSOModalConfig,
} from '@client/store/selectors/cobranding.selectors';
import { AuthModalProps } from '@client/store/types/cobranded-components/auth-modal';
import { SpecialUserType } from '@client/store/types/locked-components';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const AuthModalForCobrandSSO: FC<
  Pick<AuthModalProps, 'handleClose' | 'isOpen' | 'disableClose'>
> = ({ handleClose, isOpen, disableClose }) => {
  const cobrandTitle = useSelector(getCobrandDisplayName);
  const cobrandId = useSelector(getCobrandId);
  const ssoRedirect = useSSORedirect();
  const ssoModalConfig = useSelector(getSSOModalConfig);
  const userSpecialUserType = useSelector(getUserSpecialUserType);
  const shouldDisplaySpecialUserTypeSSOCta =
    userSpecialUserType === SpecialUserType.Restricted &&
    ssoModalConfig?.specialUserTypeSsoCtaButtonBgColor &&
    ssoModalConfig?.specialUserTypeSsoCtaButtonTextColor &&
    ssoModalConfig?.specialUserTypeSsoCtaText &&
    ssoModalConfig?.specialUserTypeSsoCtaUrl;

  const handleSpecialUserTypeSsoCtaClick = () => {
    window.open(ssoModalConfig!.specialUserTypeSsoCtaUrl!, '_blank');
  };

  return (
    <SmallModal
      className={theme.AuthModalForCobrandSSO}
      dataHcName={`auth-modal-${cobrandId}`}
      isActive={isOpen}
      handleClose={() => handleClose()}
      isHidingCloseIcon={disableClose}
      theme={theme}
      modalAriaLabel="Sign-in with partner account"
    >
      <div
        className={theme.AuthModalForCobrandSSOContent}
        aria-live="assertive"
      >
        <div
          className={theme.AuthModalForCobrandSSOTitle}
          role="heading"
          aria-level={2}
        >
          Sign in with {cobrandTitle}
        </div>
        <div className={theme.AuthModalForCobrandSSOBody}>
          {ssoModalConfig?.ssoAuthModalBodyText ? (
            <div
              // these values come from Parcon and can contain HTML in the string
              dangerouslySetInnerHTML={{
                __html: ssoModalConfig?.ssoAuthModalBodyText,
              }}
            ></div>
          ) : (
            <>
              To get the full ComeHome experience,
              <br />
              please sign in to your {cobrandTitle} account.
            </>
          )}
        </div>
        <div className={theme.AuthModalForCobrandSSOFooter}>
          {shouldDisplaySpecialUserTypeSSOCta ? (
            <PillButton
              theme={theme}
              className={theme.SmallButton}
              onClick={handleSpecialUserTypeSsoCtaClick}
              onKeyDown={onEnterOrSpaceKey(handleSpecialUserTypeSsoCtaClick)}
            >
              {ssoModalConfig?.specialUserTypeSsoCtaText}
            </PillButton>
          ) : (
            <PillButton
              ariaLabel="sign-in"
              theme={theme}
              onClick={ssoRedirect}
              onKeyDown={onEnterOrSpaceKey(ssoRedirect)}
            >
              Sign in
            </PillButton>
          )}
        </div>
      </div>
    </SmallModal>
  );
};

export default AuthModalForCobrandSSO;
